body {
  margin: 0;
}


.pagination {
	display: flex;
	list-style-type: none;
	padding: 0;
  }

  .page-item {
	margin: 0 5px;
  }

  .page-link {
	padding: 10px 15px;
	border: 1px solid #007bff;
	color: #007bff;
	text-decoration: none;
	cursor: pointer;
  }

  .page-link:hover {
	background-color: #e9ecef;
  }

  .page-item.active .page-link {
	background-color: #007bff;
	color: white;
	border-color: #007bff;
  }

  .page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	background-color: #fff;
	border-color: #dee2e6;
  }

  .alert {
	padding: 10px;
	border: 1px solid transparent;
	border-radius: 4px;
	margin-top: 20px;
  }

  .alert-warning {
	color: #856404;
	background-color: #fff3cd;
	border-color: #ffeeba;
  }

  .alert-danger {
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
  }

  .repository-entry {
	border-bottom: 1px solid #dee2e6;
	padding: 10px 0;
  }

  .repo-title {
	margin-bottom: 20px;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Yarı saydam siyah bir örtü */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Diğer içeriklerin üzerinde olacak şekilde */
}