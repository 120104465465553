#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--sapBackgroundColor);
}

.footerNoPadding::part(footer) {
  padding-inline: 0;
}

.contentNoPadding::part(content) {
  padding: 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.AnalyticalTable_table_o7v6i_1 {
  width: auto !important;
}

.loader-nonproxy {
  background: #354A5F !important;
}

.loader-proxy {
  background:  #ff7b00 !important;
}

.boldLabel{
  background-color: brown;
}

.table-wrapper {
  display: flex;
  flex-direction: column; /* Tablo ve kaydırma çubuğunu dikey olarak hizalar */
  height: 100%; /* Tablo ve kaydırma çubuğunun yüksekliğini ayarlayın */
}

.scroll-container {
  width: 100%; /* Tablonun genişliği */
  overflow-x: auto; /* Yatay kaydırma çubuğunu etkinleştirir */
}

.fixed-scroll {
  width: auto; /* Kaydırma çubuğu genişliği */
  height: 400px; /* Sabit kaydırma çubuğu yüksekliği */
  position: sticky; /* Sayfa kaydırıldığında sabit kalır */
  bottom: 0; /* Sayfanın en altına yapışır */
  background-color: white; /* Sabit kaydırma çubuğunun arka plan rengi */
  z-index: 0; /* Diğer içeriklerin üstünde görünmesini sağlar */
}

.custom-scroll {
  display: inline-block; /* İçeriği yan yana dizmek için */
  width: auto; /* İçeriğin genişliğini ayarlayın */
  height: 100%; /* Kaydırma çubuğu alanının yüksekliği */
  background-color: lightgrey; /* Kaydırma çubuğunun arka plan rengi */
}