


#group-container-basic,
#group-container-advanced,
#group-container-layout,
#group-container-premium,
#group-container-data {
  display: grid;
  gap: 0.1rem;
}

.builder-group-button {
  width: 100%;
}


.nav-link a {
  color: inherit;
  text-decoration: none;
}

.formio-brand {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.formio-brand > * {
  margin-right: 5px;
}

.navbar-brand a {
  color: inherit;
  text-decoration: none;
}

.react-logo {
  color: #0a7ea3;
}

div.spacer {
  margin: 0.5rem 0;
  line-height: 0;
}
